import React from 'react';
import clsx from 'clsx';
// material
import { styled } from '@mui/material/styles';
// components
import { CardBox, Newsletter } from 'components';
// helpers
import getNativeFormsProperties, { NativeFormsIdsType } from 'helpers/getNativeFormsUrl';
// hooks
import { useLocales } from 'hooks';
// types
import { NativeFormsComponentProps } from 'components/NativeFormsComponent/NativeFormsComponent';
//
import { stylesRoot } from './styles';

interface NewsletterCardProps {
  title?: string;
  subtitle?: string;
  onLoad?: NativeFormsComponentProps['onLoad'];
  onSend?: NativeFormsComponentProps['onSend'];
}

interface NewsletterCardAllProps extends NewsletterCardProps {
  className?: string;
}

const PREFIX = 'NewsletterCard';
const classes = {
  root: `${PREFIX}-root`,
  footer: `${PREFIX}-footer`,
};

const StyledRoot = styled(CardBox, { name: PREFIX, slot: 'root' })(({ theme }) =>
  stylesRoot(theme, classes),
);

const NewsletterCard = (props: NewsletterCardAllProps) => {
  const { className: classNameProp, title, subtitle, onLoad, onSend } = props;
  const { currentLang } = useLocales();

  const FORM_ID: NativeFormsIdsType = 'newsletter-blog';
  const formProperties = getNativeFormsProperties(FORM_ID, currentLang.value);

  return (
    <StyledRoot
      classesChildren={{ root: clsx(classes.root, classNameProp), footer: classes.footer }}
      title={title}
      subtitle={subtitle}
    >
      <Newsletter
        form={formProperties.url}
        formId={formProperties.formId}
        onLoad={onLoad}
        onSend={onSend}
      />
    </StyledRoot>
  );
};

export default NewsletterCard;
