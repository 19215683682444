import { Theme } from '@mui/material/styles';

export const stylesRoot = (theme: Theme, classes: any) => {
  const { spacing } = theme;
  return {
    '&': {
      [`&.${classes.root}`]: {
        position: 'relative',
        '&:after': {
          content: '""',
          display: 'block',
          height: spacing(7),
          background: 'inherit',
          position: 'absolute',
          top: '100%',
          left: 0,
          right: 0,
        },
      },
    },
  };
};
