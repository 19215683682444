import React from 'react';
import clsx from 'clsx';
// material
import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { AccessTimeFilledRounded as ReadingTimeIcon } from '@mui/icons-material';
// components
import { AvatarWithText, BoxWithHeader, ContentBox } from 'components';
// gatsby
import { GatsbyImage } from 'gatsby-plugin-image';
// types
import { HeroSectionProps } from 'components/HeroSection/HeroSection';
import { ChildImageSharpType } from 'types/image';
//
import { stylesRoot } from './styles';

interface HeadingProps {
  title?: string;
  background?: HeroSectionProps['background'];
  author?: {
    name?: string;
    image?: ChildImageSharpType;
  };
  readingTime?: string;
  date?: string;
  image?: ChildImageSharpType;
}

interface HeadingAllProps extends HeadingProps {
  className?: string;
}

const PREFIX = 'Heading';
const classes = {
  root: `${PREFIX}-root`,
  content: `${PREFIX}-content`,
  info: `${PREFIX}-info`,
  time: `${PREFIX}-time`,
  image: `${PREFIX}-image`,
};

const StyledRoot = styled(ContentBox, { name: PREFIX, slot: 'root' })(({ theme }) =>
  stylesRoot(theme, classes),
);

const Heading = (props: HeadingAllProps) => {
  const { className: classNameProp, title, background, author, readingTime, date, image } = props;

  return (
    <StyledRoot
      classesChildren={{ root: clsx(classes.root, classNameProp) }}
      background={background}
    >
      <BoxWithHeader
        header={title}
        headerProps={{
          variant: 'h1',
          component: 'h1',
        }}
        spaceHeader="small"
        className={classes.content}
      >
        <Box className={classes.info}>
          <Box>
            {author ? (
              <AvatarWithText title={author?.name} subtitle={date} image={author?.image} />
            ) : (
              <>
                {date && (
                  <Typography variant="caption" color="textSecondary">
                    {date}
                  </Typography>
                )}
              </>
            )}
          </Box>

          {readingTime && (
            <Box className={classes.time}>
              <ReadingTimeIcon />
              <Typography variant="caption" color="textSecondary">
                {readingTime}
              </Typography>
            </Box>
          )}
        </Box>
        {image && (
          <GatsbyImage
            className={classes.image}
            image={image.src.childImageSharp.gatsbyImageData}
            alt={image.alt}
            title={image?.title || title}
          />
        )}
      </BoxWithHeader>
    </StyledRoot>
  );
};

export default Heading;
