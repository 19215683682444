import { Theme } from '@mui/material/styles';

export const stylesRoot = (theme: Theme, classes: any) => {
  const { spacing, palette } = theme;
  return {
    '&': {
      [`&.${classes.root}`]: {
        position: 'relative',
        [`& .${classes.showMore}`]: {
          color: palette.primary.main,
        },
        [`& .${classes.footer}`]: {
          marginTop: spacing(-2),
          '& > *': {
            width: '100%',
            marginRight: 0,
          },
        },
      },
    },
  };
};
