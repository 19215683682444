import React, { ReactNode } from 'react';
import clsx from 'clsx';
// material
import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';
// components
import { ContentBox } from 'components';
//
import { stylesRoot } from './styles';

interface ContentWrapperProps {
  children?: ReactNode;
  sidebar?: ReactNode;
}

interface ContentWrapperAllProps extends ContentWrapperProps {
  className?: string;
}

const PREFIX = 'ContentWrapper';
const classes = {
  root: `${PREFIX}-root`,
};

const StyledRoot = styled(ContentBox, { name: PREFIX, slot: 'root' })(({ theme }) =>
  stylesRoot(theme, classes),
);

const ContentWrapper = (props: ContentWrapperAllProps) => {
  const { className: classNameProp, children, sidebar } = props;

  return (
    <StyledRoot
      spaceTop={false}
      background="transparent"
      classesChildren={{ root: clsx(classes.root, classNameProp) }}
    >
      <Grid container spacing={5}>
        <Grid item xs={12} md={8}>
          {children}
        </Grid>

        <Grid item xs={12} md={4}>
          {sidebar}
        </Grid>
      </Grid>
    </StyledRoot>
  );
};

export default ContentWrapper;
