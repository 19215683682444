import React from 'react';
import clsx from 'clsx';
// material
import { styled } from '@mui/material/styles';
import { Box, Grid } from '@mui/material';
// components
import { BlogPreviewCard } from 'components';
// hooks
import { usePath } from 'hooks';
// types
import { ChildImageSharpType } from 'types/image';
//
import { stylesRoot } from './styles';

interface Post {
  node: {
    id: number;
    frontmatter: {
      title: string;
      slug: string;
      date?: string;
      time_read?: string;
      author?: {
        name?: string;
        image: ChildImageSharpType;
      };
      main_photo?: ChildImageSharpType;
    };
  };
}

interface BlogListingProps {
  posts: Post[];
}

interface BlogListingAllProps extends BlogListingProps {
  className?: string;
}

const PREFIX = 'BlogListing';
const classes = {
  root: `${PREFIX}-root`,
};

const StyledRoot = styled(Box, { name: PREFIX, slot: 'root' })(({ theme }) =>
  stylesRoot(theme, classes),
);

const BlogListing = (props: BlogListingAllProps) => {
  const { className: classNameProp, posts } = props;
  const { findPagePathWithSlug } = usePath();

  return (
    <StyledRoot className={clsx(classes.root, classNameProp)}>
      <Grid container rowSpacing={7} columnSpacing={5}>
        {posts.map((post: Post) => {
          const { title, slug, date, time_read, author, main_photo } = post.node.frontmatter;
          return (
            <Grid item xs={12} sm={6} key={post.node.id}>
              <BlogPreviewCard
                image={main_photo}
                title={title}
                date={date}
                readingTime={time_read}
                author={{
                  name: author?.name,
                  image: author?.image,
                }}
                to={findPagePathWithSlug(slug, title)}
              />
            </Grid>
          );
        })}
      </Grid>
    </StyledRoot>
  );
};

export default BlogListing;
