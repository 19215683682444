import React, { useState } from 'react';
// gatsby
import { graphql } from 'gatsby';
// layouts
import { MainLayout } from 'layouts';
// material
import { Typography, Grid } from '@mui/material';
// page components
import {
  BlogListingSmall,
  BlogSidebar,
  ContentWrapper,
  NewsletterCard,
} from 'pages-components/blog-list';
import { Heading } from 'pages-components/blog-item';
// components
import { CardBox, TextMDXRenderer } from 'components';
// hooks
import { useLocales } from 'hooks';

const BlogItemPage = ({ data: { page, allMarkdown }, location, pageContext }: any) => {
  const { body, frontmatter: frontMatter } = page;
  const { edges } = allMarkdown;
  const { translate: t } = useLocales();

  const [showNewsletterHeader, setShowNewsletterHeader] = useState<boolean>(false);

  return (
    <MainLayout
      metadata={frontMatter?.metadata}
      mainPhoto={frontMatter?.main_photo?.childImageSharp?.gatsbyImageData?.images?.fallback?.src}
      path={location.pathname}
      locale={pageContext.locale}
    >
      <Heading
        background="paper"
        title={frontMatter?.title}
        date={frontMatter?.date}
        author={{
          name: frontMatter?.author?.name,
          image: frontMatter?.author?.image,
        }}
        readingTime={frontMatter?.time_read}
        image={frontMatter?.main_photo}
      />
      <ContentWrapper
        sidebar={
          <BlogSidebar>
            <Grid container columnSpacing={3} rowSpacing={4}>
              <Grid item xs={12} sm={6} md={12}>
                <NewsletterCard
                  title={showNewsletterHeader ? t('newsletter.title') : undefined}
                  subtitle={showNewsletterHeader ? t('newsletter.subtitle') : undefined}
                  onLoad={() => setShowNewsletterHeader(true)}
                  onSend={() => setShowNewsletterHeader(false)}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={12}>
                <CardBox title={t('blog.alsoRead')}>
                  {edges.length ? (
                    <BlogListingSmall posts={edges.slice(0, 3)} />
                  ) : (
                    <Typography variant="body2" align="center" color="textSecondary">
                      {t('common.emptyOtherPosts')}
                    </Typography>
                  )}
                </CardBox>
              </Grid>
            </Grid>
          </BlogSidebar>
        }
      >
        <TextMDXRenderer>{body}</TextMDXRenderer>
      </ContentWrapper>
    </MainLayout>
  );
};

export default BlogItemPage;

export const pageQuery = graphql`
  query ($id: String!, $locale: String!, $localeRegex: String!) {
    allMarkdown: allMdx(
      filter: {
        slug: { ne: "root" }
        id: { ne: $id }
        frontmatter: { post: { in: true } }
        fileAbsolutePath: { regex: $localeRegex }
      }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          id
          slug
          frontmatter {
            slug
            title
            date(formatString: "LL", locale: $locale)
            post
            time_read
            author {
              name
              image {
                src {
                  name
                  childImageSharp {
                    gatsbyImageData(width: 40, height: 40)
                  }
                }
                alt
                title
              }
            }
            main_photo {
              src {
                name
                childImageSharp {
                  gatsbyImageData(width: 212)
                }
              }
              alt
              title
            }
          }
          fileAbsolutePath
          parent {
            ... on File {
              name
            }
          }
        }
      }
    }
    page: mdx(id: { eq: $id }) {
      body
      id
      frontmatter {
        title
        slug
        post
        metadata {
          title
          description
          og_title
          og_description
          og_type
          og_image {
            publicURL
            name
          }
          fb_app_id
          twitter_card
          twitter_creator
          twitter_title
          twitter_description
          twitter_image {
            publicURL
            name
          }
          twitter_url
          keywords
        }
        date(formatString: "LL", locale: $locale)
        author {
          name
          image {
            src {
              name
              childImageSharp {
                gatsbyImageData(width: 40)
              }
            }
            alt
            title
          }
        }
        time_read
        main_photo {
          src {
            name
            childImageSharp {
              gatsbyImageData(width: 1152)
            }
          }
          alt
          title
        }
      }
    }
  }
`;
