import React, { ReactNode } from 'react';
import clsx from 'clsx';
// material
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
//
import { stylesRoot } from './styles';

interface BlogSidebarProps {
  children?: ReactNode;
}

interface BlogSidebarAllProps extends BlogSidebarProps {
  className?: string;
}

const PREFIX = 'BlogSidebar';
const classes = {
  root: `${PREFIX}-root`,
};

const StyledRoot = styled(Box, { name: PREFIX, slot: 'root' })(({ theme }) =>
  stylesRoot(theme, classes),
);

const BlogSidebar = (props: BlogSidebarAllProps) => {
  const { className: classNameProp, children } = props;

  return <StyledRoot className={clsx(classes.root, classNameProp)}>{children}</StyledRoot>;
};

export default BlogSidebar;
