import React from 'react';
import clsx from 'clsx';
// material
import { styled } from '@mui/material/styles';
import { List } from '@mui/material';
// components
import { BlogPreviewListItem } from 'components';
// hooks
import { usePath } from 'hooks';
// types
import { ChildImageSharpType } from 'types/image';
//
import { stylesRoot } from './styles';

interface Post {
  node: {
    id: number;
    frontmatter: {
      title: string;
      slug: string;
      date?: string;
      author?: {
        name?: string;
        image: ChildImageSharpType;
      };
    };
  };
}

interface BlogListingSmallProps {
  posts: Post[];
}

interface BlogListingSmallAllProps extends BlogListingSmallProps {
  className?: string;
}

type ExtraProps = {
  component: React.ElementType;
};

const PREFIX = 'BlogListingSmall';
const classes = {
  root: `${PREFIX}-root`,
};

const StyledRoot = styled(List, { name: PREFIX, slot: 'root' })<ExtraProps>(({ theme }) =>
  stylesRoot(theme, classes),
);

const BlogListingSmall = (props: BlogListingSmallAllProps) => {
  const { className: classNameProp, posts } = props;
  const { findPagePathWithSlug } = usePath();

  return (
    <StyledRoot disablePadding component="div" className={clsx(classes.root, classNameProp)}>
      {posts.map((post: Post) => {
        const { title, slug, date, author } = post.node.frontmatter;
        return (
          <BlogPreviewListItem
            key={post.node.id}
            title={title}
            date={date}
            author={{
              name: author?.name,
              image: author?.image,
            }}
            to={findPagePathWithSlug(slug, title)}
          />
        );
      })}
    </StyledRoot>
  );
};

export default BlogListingSmall;
