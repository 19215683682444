import { Theme } from '@mui/material/styles';

export const stylesRoot = (theme: Theme, classes: any) => {
  const { breakpoints, palette, shape, spacing, typography } = theme;
  return {
    '&': {
      [`&.${classes.root}`]: {
        position: 'relative',

        '&.Heading-content': {
          '& > *:not(:last-child)': {
            marginBottom: spacing(3),
            [breakpoints.up('sm')]: {
              marginBottom: spacing(4),
            },
            [breakpoints.up('lg')]: {
              marginBottom: spacing(5),
            },
          },
        },
      },
      [`& .${classes.content}`]: {},
      [`& .${classes.info}`]: {
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'center',
        marginBottom: spacing(3),
        [breakpoints.up('sm')]: {
          marginBottom: spacing(4),
        },
        [breakpoints.up('lg')]: {
          marginBottom: spacing(5),
        },
        '& > *:first-of-type': {
          flex: 1,
        },
      },
      [`& .${classes.time}`]: {
        paddingLeft: spacing(2),
        display: 'flex',
        alignItems: 'center',
        '& span': {
          fontFamily: typography.h1.fontFamily,
        },
        '& svg': {
          fontSize: 16,
          marginRight: spacing(1),
          color: palette.text.disabled,
        },
      },
      [`& .${classes.image}`]: {
        overflow: 'hidden',
        height: 280,
        borderRadius: shape.borderRadiusMd,
        [breakpoints.up('sm')]: {
          height: 320,
        },
        [breakpoints.up('md')]: {
          height: 350,
        },
      },
    },
  };
};
