import React from 'react';
import clsx from 'clsx';
// material
import { styled } from '@mui/material/styles';
import { TypographyProps } from '@mui/material';
// components
import { BoxWithHeader, ContentBox } from 'components';
// types
import { HeroSectionProps } from 'components/HeroSection/HeroSection';
//
import { stylesRoot } from './styles';

interface TypographyExtendsProps extends TypographyProps {
  component?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'div' | 'span';
}

interface HeadingProps {
  title?: string;
  titleProps?: TypographyExtendsProps;
  background?: HeroSectionProps['background'];
}

interface HeadingAllProps extends HeadingProps {
  className?: string;
}

const PREFIX = 'Heading';
const classes = {
  root: `${PREFIX}-root`,
};

const StyledRoot = styled(ContentBox, { name: PREFIX, slot: 'root' })(({ theme }) =>
  stylesRoot(theme, classes),
);

const Heading = (props: HeadingAllProps) => {
  const { className: classNameProp, title, background, titleProps = {} } = props;

  return (
    <StyledRoot
      classesChildren={{ root: clsx(classes.root, classNameProp) }}
      background={background}
    >
      {title && <BoxWithHeader header={title} headerProps={titleProps} />}
    </StyledRoot>
  );
};

export default Heading;
